export default function (headers, customFilterable, addAction) {
  const filterableColumns = [
    ...headers
      .filter(h => h.filterable)
      .map(h => h.value || h.dbColumn),
    ...customFilterable
  ]

  const defaultHeader = {
    class: 'bg-gray-100 uppercase',
  }
  const actionHeader = {
    text: '',
    class: 'bg-gray-100 uppercase',
    value: 'actions',
    width: 50,
    sortable: false
  }

  const validHeaders = headers.filter(h => h.value);
  for (let header of validHeaders) {
    Object.assign(header, defaultHeader);
  }

  if (addAction)
    headers.push(actionHeader)

  return {
    headers,
    filterableColumns,
  }
}