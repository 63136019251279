import { ref, customRef } from '@vue/composition-api';
import { debounce } from 'lodash'

const debouncedRef = (initialValue, delay, immediate) => {
  const state = ref(initialValue)
  const debouncedRef = customRef((track, trigger) => ({
    get() {
      track()
      return state.value
    },
    set: debounce(
      value => {
        state.value = value
        trigger()
      },
      delay,
      { leading: immediate }
    ),
  }))
  return debouncedRef
}

export default debouncedRef