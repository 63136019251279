import { ref } from '@vue/composition-api'
import debouncedRef from '@/helpers/debouncedRef'

export default function (defaultOptions) {
  const defaults = Object.assign({
    page: 1,
    itemsPerPage: 25,
    sortDesc: [false],
    sortBy: [''],
  }, defaultOptions)
  const options = ref(defaults);
  const search = debouncedRef('', 200, true)
  return { search, options }
}