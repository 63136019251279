import {  computed } from "@vue/composition-api";
import dataSearch from './dataSearch'

export default function (dataTableOptions, filterableColumns, queryValues, search) {
  const getSort = (by, [desc]) => {
    const sort = {}
    for(let value of by) {
      sort[value] = desc ? -1 : 1
    }
    return sort;
  }

  const params = computed(() => {
    const {
      itemsPerPage: $limit,
      page,
      sortDesc,
      sortBy
    } = dataTableOptions.value;

    const limitedDefaults = $limit === -1 ? 999 : $limit;
    const pageData = {
      $limit: limitedDefaults,
      $skip: (page - 1) * limitedDefaults,
      $sort: getSort(sortBy, sortDesc)
    }
    const fetchQuery = Object.assign({}, queryValues.value, pageData);

    if (search.value !== '') {
      const { fetchSearch }
        = dataSearch(search.value, filterableColumns);
      Object.assign(fetchQuery, fetchSearch.value)
    }

    return {
      query: {
        ...fetchQuery
      },
    }
  })

  return params;
}
