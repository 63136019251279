import { computed } from "@vue/composition-api";

export default function (search, columns) {
  const fetchSearch = computed(() => {
    let searchQuery = null;
    
    if (search) {
      search = search.trim()
      searchQuery = { $or: [] }

      for(let col of columns) {
        searchQuery.$or.push({
          [col]: {
            $ilike: `%${ search }%`
          }
        })
      }
    }

    return searchQuery;
  });

  const storeSearch = computed(() => {
    let searchQuery = null;
    
    if (search) {
      searchQuery = { $or: [] }

      for(let col of columns) {
        searchQuery.$or.push({
          [col]: new RegExp(search, 'i')
        })
      }
    }
    return searchQuery;
  });

  return { fetchSearch, storeSearch }
}
