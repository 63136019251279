import feathersClient from './../api-client'
import dataTableParams from '@/helpers/dataTableParams'
import useApiFind from './../api/useApiFind'
import { computed } from '@vue/composition-api'
import dataTableOptions from '@/helpers/dataTableOptions'

export default function (service, headers, queryValues, queryOptions, requestOptions = {}) {
  let FindModel = null;
  let ResultModel = null;

  if (!Array.isArray(service)) {
    const { FeathersVuexModel: Model } = feathersClient.service(service);
    FindModel = Model

  } else {
    const [findService, resultService] = service;

    const { FeathersVuexModel: findModel } = feathersClient.service(findService);
    FindModel = findModel

    const { FeathersVuexModel: resultModel } = feathersClient.service(resultService);
    ResultModel = resultModel;
  }

  const { search, options } = dataTableOptions(queryOptions);

  const fetchParams = dataTableParams(
    options,
    headers,
    queryValues,
    search
  )

  const response = useApiFind({
    model: FindModel,
    resultModel: ResultModel,
    params: fetchParams,
    queryWhen: computed(() => queryValues.value && options),
    immediate: false
  })

  if (requestOptions.refreshOnCreate)
    FindModel.on('created', response.findItems)

  return {
    ...response,
    search,
    options
  }
}